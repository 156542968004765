import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import CardComponent from '../../../../newComponents/CardComponent/CardComponent';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLazyGetTransportersListQuery } from '../../../../store/newApi/organizations/organizations.js';
import { getInitials } from '../../../../helpers/formatText';
import Preloader from '../../../../components/Preloader/Preloader';
import Filter from '../../../../newComponents/Filter/Filter.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/transporters.js';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { FILTER_TYPES } from '../../../../config/filter';

function AccountEmplTransporters() {
	const dispatch = useDispatch();
	const [trigger, { data, isLoading }] = useLazyGetTransportersListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const filters = useSelector((state) => state['transportersRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);

	// Для режима выбора карточек
	const [selectionMode, setSelectionMode] = useState(false);
	const [selectedCards, setSelectedCards] = useState([]);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	// Обработка выбора карточек
	const handleCardSelect = (id) => {
		setSelectedCards((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((cardId) => cardId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Перевозчики'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium">
						Добавить перевозчика
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					<div className={styles.account__filter}>
						<Filter
							onSetFilter={handleSetFilter}
							onResetFilter={handleResetFilter}
							parameters={[
								{
									name: 'is_manager_confirmed',
									title: 'Статус',
									type: FILTER_TYPES.CHECKBOX,
									elements: [
										{ value: 1, label: 'Одобрен' },
										{ value: 2, label: 'Ожидает одобрения' },
										{ value: 3, label: 'Отклонен' },
									],
								},
								{
									name: 'terminal',
									title: 'Терминал',
									type: FILTER_TYPES.INPUTDROPDOWN,
									elements: elements,
								},
							]}
						/>
					</div>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index}>
									<CardComponent
										avatar={getInitials(card?.organization_name)}
										selectionMode={selectionMode}
										isSelected={selectedCards.includes(card?.id)}
										onSelectCard={() => handleCardSelect(card?.id)}
										title={card?.organization_name}
										rightText={card?.is_manager_confirmed}
										status={
											card?.is_manager_confirmed == 'Одобрен'
												? 'successful'
												: card?.is_manager_confirmed == 'Ожидает одобрения'
													? 'warning'
													: card?.is_manager_confirmed == 'Отклонен'
														? 'error'
														: {}
										}
										leftAdditionalInfo={[
											{
												name: 'ИНН:',
												text: `${card?.inn ? card?.inn : '-'}`,
											},
										]}
										rightAdditionalInfo={[
											{
												name: 'Почта:',
												text: `${card?.contact_email ? card?.contact_email : '-'}`,
											},
										]}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplTransporters;
