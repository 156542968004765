import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FormWrapper } from '../FormWrapper';
import InputText from '../../Inputs/InputText/InputText';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useInitFormState } from './hooks/useInitFormState';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { entriesForm } from './EntriesForm.style';
import { useNavigate } from 'react-router-dom';

const updateSchema = z.object({
	container_number: z.string().optional(),
	vehicle_driver: z.string().optional(),
	vehicle_display: z.string().optional(),
	vehicle_trailer_number: z.string().optional(),
	seal_number: z.string().optional(),
});

export const EntriesForm = () => {
	const resolver = useMemo(() => zodResolver(updateSchema));
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const navigate = useNavigate();

	const methods = useForm({
		defaultValues: {
			container_number: undefined,
			vehicle_driver: undefined,
			vehicle_display: undefined,
			vehicle_trailer_number: undefined,
			seal_number: undefined,
		},
		resolver: resolver,
	});
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods });

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = methods;

	const onSubmit = useCallback(
		(value) => {
			handleUpdate(value);
		},
		[handleUpdate],
	);

	useInitFormState({ ...methods });

	return (
		<FormWrapper onSubmit={onSubmit} {...methods}>
			<Grid container sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Номер контейнера</Typography>
					<Controller
						name="container_number"
						control={control}
						render={({ field }) => <InputText {...field} disabled placeholder="Номер контейнера" />}
					/>
				</Grid>

				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Водитель</Typography>
					<Controller
						name="vehicle_driver"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Водитель" />}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Номер автомобиля</Typography>
					<Controller
						name="vehicle_display"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Номер автомобиля" />}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Номер прицепа</Typography>
					<Controller
						name="vehicle_trailer_number"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Номер прицепа" />}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Номер пломбы</Typography>
					<Controller
						name="seal_number"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Номер пломбы" />}
					/>
				</Grid>
			</Grid>
			<Box sx={entriesForm(isDesktop)}>
				<Button variant="outlined" color="primary" onClick={() => navigate('/accountEmpl/entries')}>
					Назад
				</Button>
				<Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
					Сохранить
				</Button>
			</Box>
		</FormWrapper>
	);
};
