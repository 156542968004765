import { Drawer, Box, Stack, IconButton, Button } from '@mui/material';
import { drawerModal } from './DrawerModal.styles.js';
import IconCross from '../../components/Icons/IconCross.jsx';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Общий компонент с модалкой
 * @param {*} param0 
 * @returns 
 */
export const DrawerModal = ({ isOpen, handleClose, actions, children }) => {
    const isTablet = useMediaQuery((theme) => theme.breakpoints.up('tablet'));
    return (
        <Box sx={drawerModal.wrapper}>
            {isOpen && isTablet && (
                <IconButton sx={drawerModal.btnClose} onClick={handleClose}>
                    <IconCross color={'primary-active'} size={'medium'} />
                </IconButton>
            )}
            <Drawer sx={drawerModal.drawer} anchor="right" open={isOpen} onClose={handleClose}>
                <Box sx={drawerModal.content}>
                    <Stack justifyContent="space-between" height={1}>
                        <Box sx={{ padding: '16px 8px 8px 16px' }}>
                            {children}
                        </Box>
                        <Box sx={drawerModal.actions}>
                            {actions}
                            {isOpen && !isTablet && (
                                <Button onClick={handleClose} variant="outlined" color="primary">
                                    Закрыть
                                </Button>
                            )}
                        </Box>
                    </Stack>
                </Box>
            </Drawer>
        </Box>
    )
}