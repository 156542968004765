import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { getExportExcel } from '../../../../store/rtkSlices/containers.js';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useGetLinesListQuery, useLazyGetContainersListQuery } from '../../../../store/newApi/containers/containers';
import { formatInitialDate } from '../../../../helpers/formatDate';
import Filter from '../../../../newComponents/Filter';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/containers.js';
import { Button } from '@mui/material';
import BtnDropdown from '../../../../newComponents/BtnDropdown/BtnDropdown';
import { ContainerForm } from '../../../../newComponents/Form/ContainerForm/ContainerForm';
import Cookies from 'js-cookie';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';

function AccountEmplContainers() {
	const userType = Cookies.get('userType');
	const dispatch = useDispatch();

	const [fetchContainers, { data, isLoading, isFetching, isError }] = useLazyGetContainersListQuery({});
	const { getExportExcel_pending } = useSelector((state) => state.allContainers);

	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

	const handleCreate = useCallback(() => {
		setRowSelection({});
		setIsCreateFormOpen(true);
	}, []);

	const handleUpdate = useCallback(() => {
		setIsCreateFormOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setRowSelection({});
		setIsCreateFormOpen(false)
	}, [setRowSelection]);

	const handleExportExcel = () => {
		let viewCols = '';
		if (Object.keys(rowSelection).length !== 0) {
			viewCols += 'container_ids_in=' + Object.keys(rowSelection).join(',');
		}
		dispatch(getExportExcel(viewCols));
	};

	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

	// колонки
	const columnsList = useMemo(() => {
		const baseColumns = [
			{
				accessorFn: (row) => row.status_display,
				id: 'status',
				header: 'Статус',
				size: 192,
			},
			{
				accessorFn: (row) => row.line_display,
				id: 'line',
				header: 'Линия',
				size: 232,
			},
			{
				accessorFn: (row) => row.container_number,
				id: 'container_number',
				header: '№ контейнера',
				size: 230,
			},
			{
				accessorFn: (row) => row.size_display,
				id: 'size',
				header: 'Размер',
				size: 200,
			},
			{
				accessorFn: (row) => row.type_display,
				id: 'type',
				header: 'Тип',
				size: 180,
			},
			{
				accessorFn: (row) => row.booking,
				id: 'booking',
				header: 'Букинг',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_filled ? 'Да' : '-'),
				id: 'is_filled',
				header: 'Заполнен',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_dangerous ? 'Да' : '-'),
				id: 'is_dangerous',
				header: 'Опасный груз',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_damaged ? 'Да' : '-'),
				id: 'is_damaged',
				header: 'Поврежден',
				size: 280,
			},
			{
				accessorFn: (row) => row.terminal_display,
				id: 'terminal',
				header: 'Терминал',
				size: 230,
			},
			{
				accessorFn: (row) => row.created_at && formatInitialDate(row.created_at),
				id: 'created_at',
				header: 'Дата создания',
				size: 230,
			},
		]; 

		if (userType == 1) {
			baseColumns.push({
				accessorFn: (row) => {
					const url = `/accountEmpl/clients?client=${row.client}`;
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.client_display}
						</a>
					);
				},
				id: 'org',
				header: 'Клиент',
				size: 280,
				sortDescFirst: false,
			});
		}

		return baseColumns;
	}, [userType]);

	// расположение колонок
	const columnOrdering = [
		'mrt-row-select',
		'status',
		'line',
		'container_number',
		'size',
		'type',
		'org',
		'booking',
		'is_filled',
		'is_dangerous',
		'is_damaged',
		'terminal',
		'created_at',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const menuItems = [
		{
			text: 'Вручную',
			onClick: handleCreate,
		},
		{ text: 'Импорт из excel', onClick: () => {} },
	];

	const { data: dataLines } = useGetLinesListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataLines)) {
			const newElements = dataLines.map((item) => ({
				value: item.id,
				label: item.line_name,
			}));
			setElements(newElements);
		}
	}, [dataLines]);

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elementsTerminals, setElementsTerminals] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElementsTerminals(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Контейнеры'}
				btnDesktop={[
					selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="medium" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<BtnDropdown mainButtonText="Добавить контейнеры" dropdownItems={menuItems} />,
					<Button variant="contained" color="primary" size="medium" onClick={handleExportExcel}>
						Экспортировать
					</Button>,
				]}
				btnMobile={[
					selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="small" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<BtnDropdown mainButtonText="Добавить" dropdownItems={menuItems} size="small" />,
				]}
			/>

			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'Ожидается' },
									{ value: 2, label: 'Принят' },
									{ value: 3, label: 'На ремонте' },
									{ value: 4, label: 'Ожидает вывоза' },
									{ value: 5, label: 'Вывезен' },
								],
							},
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elementsTerminals,
							},
							{
								name: 'client_id',
								title: 'Клиент',
								type: FILTER_TYPES.INPUTDROPDOWNCLIENT,
							},
							{
								name: 'line',
								title: 'Линия',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: 'size',
								title: 'Размер',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: '20' },
									{ value: 2, label: '40' },
									{ value: 3, label: '45' },
								],
							},
							{
								name: 'type',
								title: 'Тип',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'DC' },
									{ value: 2, label: 'DV' },
									{ value: 3, label: 'HC' },
									{ value: 4, label: 'PW' },
									{ value: 5, label: 'OT' },
									{ value: 6, label: 'OT HC' },
									{ value: 7, label: 'RF' },
									{ value: 8, label: 'RF HC' },
									{ value: 9, label: 'FR' },
									{ value: 10, label: 'FR HC' },
									{ value: 11, label: 'TC' },
									{ value: 12, label: 'HR' },
								],
							},
							{
								name: ['is_damaged', 'is_dangerous', 'is_filled'],
								title: 'Характеристики',
								type: FILTER_TYPES.SWITCH,
								elements: [
									{ value: 0, label: 'Поврежден' },
									{ value: 1, label: 'Опасный груз' },
									{ value: 2, label: 'Заполнен' },
								],
							},

							{
								name: ['date_from', 'date_to'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetchContainers}
						isLoading={isLoading}
						isFetching={isFetching}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="containersRtk"
					/>
					<ContainerForm
						isOpen={isCreateFormOpen}
						handleClose={handleClose}
						entityIds={selectedRows}
						refetchData={() => fetchContainers({ page: 1, page_size: 30 })}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplContainers;
