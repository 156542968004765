import { useGetRepairComponentsQuery } from '../../../store/newApi/service/service';
import { InputAutocomplete } from '../../Inputs/InputAutocomplete/InputAutocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import { Typography } from '@mui/material';

export const RepairComponentsInputAutocompleteController = ({ searchParams, ...rest }) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const { repairComponentsOptions = [] } = useGetRepairComponentsQuery(
		{ ...searchParams },
		{
			selectFromResult: ({ data }) => ({
				repairComponentsOptions: data?.results?.map((e) => ({ label: e.name, id: e.id })) || [],
			}),
		},
	);

	return (
		<CollapseBlock defaultOpen={!!errors?.repair_component} title={<span>Компонент</span>}>
			<Controller
				name="repair_component"
				control={control}
				render={({ field }) => (
					<>
						<InputAutocomplete {...field} placeholder="Выберите или введите вручную" options={repairComponentsOptions} />
						{errors?.repair_component && (
							<Typography color="error" variant="caption">
								{errors?.repair_component?.repair_component}
							</Typography>
						)}
					</>
				)}
			/>
		</CollapseBlock>
	);
};
