import { Button, useMediaQuery } from '@mui/material';
import Header from '../../../../../newComponents/Header/Header';
import styles from './AccountEmplRepairCreate.module.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChooseContainerStep } from './components/ChooseContainerStep/ChooseContainerStep';
import { ChooseRepairStep } from './components/ChooseRepairStep/ChooseRepairStep';
import { ChooseServicesStep } from './components/ChooseServicesStep/ChooseServicesStep';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetServiceOrdersByIdQuery, useLazyGetServiceOrdersByIdQuery, useUpdateRepairOrderMutation } from '../../../../../store/newApi/service/service';
import { useLazyGetContainersListQuery } from '../../../../../store/newApi/containers/containers';
import InputText from '../../../../../newComponents/Inputs/InputText/InputText';
import { isClient } from '../../../../../shared/auth/isClient';

const CommentStep = ({ onCancel, onBack, repairOrderId, serviceOrderInfo }) => {
    const [updateRepairOrder, { data, isSuccess, reset }] = useUpdateRepairOrderMutation();
    const [comment, setComment] = useState("");

    const repairGroups = useMemo(() => serviceOrderInfo?.repair_group || [], [serviceOrderInfo]);

    const handleFinish = useCallback(() => {
        updateRepairOrder({
            id: repairOrderId,
            base_price: serviceOrderInfo.base_price,
            total_price: serviceOrderInfo.total_price,
            status: 1,
            comment
        });
    }, [repairOrderId, serviceOrderInfo, comment, updateRepairOrder]);

    const isReadyToFinish = useMemo(() => {
        return repairGroups.filter(rp => rp.status !== 1).length === 0 && !isClient();
    }, [repairGroups]);

    useEffect(() => {
        if (isSuccess) {
            reset();
            onCancel();
        }
    }, [isSuccess, reset, onCancel]);
    
    useEffect(() => {
        if (serviceOrderInfo?.comment)
            setComment(serviceOrderInfo?.comment)
    }, [serviceOrderInfo]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'space-between', flexGrow: 1 }}>
            <InputText
                defaultValue={serviceOrderInfo?.comment}
                value={comment}
                placeholder="Введите текст"
                height='200px'
                onChange={(e) => setComment(e.target.value)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button variant="outlined" color='primary' size="medium" onClick={onCancel}>
                    Отмена
                </Button>
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button variant="contained" color='primary' size="medium" onClick={onBack}>
                        Назад
                    </Button>
                    <Button disabled={!isReadyToFinish} variant="contained" color='primary' size="medium" onClick={handleFinish}>
                        Завершить
                    </Button>
                </div>
            </div>
        </div>
    )
}

export const AccountEmplRepairCreate = () => {
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('tablet'));
    const location = useLocation();
    const navigate = useNavigate();
    const { repairOrderId } = useParams();
    const [params] = useSearchParams();
    const step = params.get('step');
    const repairGroupId = params.get('repairGroupId');

    const [fetchServiceOrder, { data: serviceOrder }] = useLazyGetServiceOrdersByIdQuery()
    const [getContainers, { container }] = useLazyGetContainersListQuery({
        selectFromResult: ({ data }) => ({
			container: data?.results ? data?.results[0] : null,
		}),
    });
    const [updateRepairOrder, { data, isSuccess: isUpdateOrderSuccess, reset: resetUpdateOrder }] = useUpdateRepairOrderMutation();

    const [currentStep, setCurrentStep] = useState(0);
    const [containerInfo, setContainerInfo] = useState(null);
    const [serviceOrderInfo, setServiceOrder] = useState(null);

    const isEditMode = useMemo(() => !!repairOrderId, [repairOrderId]);

    const isReadyToAccept = useMemo(() => {
        return serviceOrder?.repair_group?.filter(rp => rp.status === 1)?.length === 0 && isClient();
    }, [serviceOrder]);

    const refetchServiceOrder = useCallback(() => {
        fetchServiceOrder({ id: repairOrderId })
    }, [repairOrderId, fetchServiceOrder]);

    useEffect(() => {
        refetchServiceOrder()
    }, [refetchServiceOrder]);

    // Если редактируем serviceOrder по id
    useEffect(() => {
        if (serviceOrder) {
            getContainers({ container_number: serviceOrder.container })
            setServiceOrder(serviceOrder);
        }
    }, [serviceOrder, getContainers, setServiceOrder]);

    // Если пришла инфа про контейнер
    useEffect(() => {
        if (container) {
            setContainerInfo(container);
        }
    }, [container])

    // Если передали шаг из url
    useEffect(() => {
        if (step)
            setCurrentStep(+step);
    }, [step]);

    const handleGoToContainerStep = () => {
        navigate(`${location.pathname}?step=0`)
    }

    const handleGoToRepairsStep = () => {
        navigate(`${location.pathname}?step=1`)
    }

    const handleGoToServicesStep = (repairGroupId) => {
        navigate(`${location.pathname}?step=2&repairGroupId=${repairGroupId}`)
    }

    const handleGoToCommentStep = () => {
        navigate(`${location.pathname}?step=3`)
    }

    const handleGoToRepairsListPage = () => {
        if (isClient())
            navigate('/accountClient/repair')
        else
            navigate('/accountEmpl/repair')
    }

    const handleAcceptOrder = useCallback(() => {
        updateRepairOrder({
            id: repairOrderId,
            base_price: serviceOrder.base_price,
            total_price: serviceOrder.total_price,
            status: 2,
            comment: serviceOrder.comment
        });
    }, [serviceOrder, repairOrderId, updateRepairOrder]);

    const hideTabs = useMemo(() => {
        return currentStep === 2;
    }, [currentStep]);

    useEffect(() => {
        if (isUpdateOrderSuccess) {
            handleGoToRepairsListPage();
            resetUpdateOrder();
        }
    }, [isUpdateOrderSuccess, resetUpdateOrder]);

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <ChooseContainerStep
                    onSetContainer={setContainerInfo}
                    containerInfo={containerInfo}
                    setServiceOrder={setServiceOrder}
                    onNext={handleGoToRepairsStep}
                    onBack={handleGoToRepairsListPage}
                    isEditMode={isEditMode}
                />
            case 1:
                return <ChooseRepairStep
                    onChooseRepair={handleGoToServicesStep}
                    refetchRepairOrder={refetchServiceOrder}
                    repairOrderId={repairOrderId}
                    onNext={handleGoToCommentStep}
                    onBack={handleGoToContainerStep}
                    onCancel={handleGoToRepairsListPage}
                />
            case 2:
                return <ChooseServicesStep
                    repairGroupId={repairGroupId}
                    containerInfo={containerInfo}
                    onBack={handleGoToRepairsStep}
                />
            case 3:
                return <CommentStep
                    onBack={handleGoToRepairsStep}
                    onCancel={handleGoToRepairsListPage}
                    repairOrderId={repairOrderId}
                    serviceOrderInfo={serviceOrderInfo}
                />
            default:
                return null;
        }
    }

    return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Header
            text={'Ремонт'}
            btnDesktop={[
                isClient() && <Button disabled={!isReadyToAccept} variant="contained" color="primary" size="medium" onClick={handleAcceptOrder}>
                    Подтвердить акт
                </Button>,
            ]}
            btnMobile={[
                isClient() && <Button disabled={!isReadyToAccept} variant="contained" color="primary" size="small" onClick={handleAcceptOrder}>
                    Подтвердить акт
                </Button>,
            ]}
        />
        <div className={styles.content}>
            {!hideTabs && <div className={styles.tabs}>
                <Button variant="contained" color={currentStep === 0 ? 'secondary' : 'primary'} size={isDesktop ? "medium" : "small"} onClick={handleGoToContainerStep}>
                    Контейнер
                </Button>
                <Button disabled={!serviceOrderInfo} variant="contained" color={currentStep === 1 ? 'secondary' : 'primary'} size={isDesktop ? "medium" : "small"} onClick={handleGoToRepairsStep}>
                    Ремонтные работы
                </Button>
                <Button disabled={!serviceOrderInfo} variant="contained" color={currentStep === 3 ? 'secondary' : 'primary'} size={isDesktop ? "medium" : "small"} onClick={handleGoToCommentStep}>
                    Комментарии
                </Button>
            </div>}
            <div className={styles.steps}>
                {renderStep()}
            </div>
        </div>
    </div>
}