import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getExportExcelApi } from '../../api/containers/getExportExcelApi';
import { buildQueryString } from '../../newComponents/Filter/utils';

const initialState = {
	filter: {
		page: 1,
		page_size: 30,
	},
};

// Экспорт таблицы в excel
export const getExportExcel = createAsyncThunk('containers/getExportExcel', async (query, { rejectWithValue, getState }) => {
	try {
		const state = getState();
		let response; 
		if (query === '') {
			response = await getExportExcelApi(query, buildQueryString(state.containersRtk.filter).replace(/page=\d+&page_size=\d+&?/, '')); 
		} else {
			response = await getExportExcelApi(query + '&', buildQueryString(state.containersRtk.filter).replace(/page=\d+&page_size=\d+&?/, '')); 
		} 
		return response;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const containersSlice = createSlice({
	name: 'containers',
	initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filter = action.payload;
		},
		resetFilter: (state) => {
			state.filter = initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getExportExcel.fulfilled, (state, action) => {
			// Скачивание файла
			let blob = new Blob([action.payload.data], {
				type: 'excel/xlsx',
			});
			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			// Форматирование даты для названия файла
			let formattedDate = new Date()
				.toLocaleString('ru-RU', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
				})
				.replace(',', '');
			link.download = `Таблица контейнеров_${formattedDate}.xlsx`;
			link.click();
		});
	},
});

export const { setFilter, resetFilter } = containersSlice.actions;
export default containersSlice.reducer;
