import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NumberRangeInput from '../../../Inputs/NumberRangeInput/NumberRangeInput';

const InputRangeGroup = ({ name, title, text, state, onChange }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const [minNum, setMinNum] = useState(state[0] ? state[0] : '');
	const [maxNum, setMaxNum] = useState(state[1] ? state[1] : '');

	useEffect(() => {
		setMinNum(state[0] || '');
		setMaxNum(state[1] || '');
	}, [state]);

	useEffect(() => {
		onChange(name[0], minNum);
		onChange(name[1], maxNum);
	}, [minNum, maxNum]);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', '&:hover': { cursor: 'pointer' } }} onClick={toggleOpen}>
				<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
					{title}
				</Typography>
				<IconButton>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
			</Box>
			{isOpen && (
				<NumberRangeInput
					text={text}
					minNumber={minNum}
					maxNumber={maxNum}
					onMinNumberChange={setMinNum}
					onMaxNumberChange={setMaxNum}
				/>
			)}
		</>
	);
};

export default InputRangeGroup;
