import { useCallback, useEffect } from "react";
import { useLazyGetContainerByIdQuery } from "../../../../../../../store/newApi/containers/containers";
import styles from './ChooseContainerStep.module.css';
import { ContainerInputAutocomplete } from "../../../../../../../newComponents/Form/Fields/ContainerInputAutocomplete";
import InputText from "../../../../../../../newComponents/Inputs/InputText/InputText";
import { Button } from "@mui/material";
import { useCreateServiceOrderMutation } from "../../../../../../../store/newApi/service/service";
import { useLocation, useNavigate } from "react-router-dom";

export const ChooseContainerStep = ({ onSetContainer, setServiceOrder, containerInfo, onNext, onBack, isEditMode }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [fetchContainer, { container, loading: loadingClients }] = useLazyGetContainerByIdQuery({
        selectFromResult: ({ data }) => ({
            container: data,
        }),
    });

    const [createServiceOrder, { data, isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateServiceOrder }] = useCreateServiceOrderMutation();

    useEffect(() => {
        if (container)
            onSetContainer(container);
    }, [container, onSetContainer]);

    const handleContainerChoose = useCallback((id) => {
        fetchContainer({ id })
    }, [fetchContainer]);

    const handleNext = useCallback(() => {
        if (!isEditMode)
            createServiceOrder({
                containerId: container.id,
            })
        else
            onNext();
    }, [container, createServiceOrder, isEditMode, onNext])

    useEffect(() => {
        if (isCreateSuccess && data) {
            setServiceOrder(data);
            resetCreateServiceOrder();
            navigate(`${location.pathname}/${data.id}?step=1`)
        }
    }, [isCreateSuccess, resetCreateServiceOrder, setServiceOrder, data, navigate, location])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
            <div className={styles.containerFormTemplate}>
                <div class={styles.item}>
                    {!isEditMode && <ContainerInputAutocomplete value={containerInfo?.id} onChange={handleContainerChoose} searchParams={{ repair_order_available: true }} labelText="Контейнер" />}
                    {isEditMode && <InputText labelText="Контейнер" disabled value={containerInfo?.container_number} />}
                </div>
                {containerInfo && (
                    <>
                        <InputText labelText="Тип" disabled value={containerInfo.type_display} />
                        <InputText labelText="Размер" disabled value={containerInfo.size_display} />
                        <div class={styles.item}>
                            <InputText labelText="Клиент" disabled value={containerInfo.client_display} />
                        </div>
                        <div class={styles.item}>
                            <InputText labelText="Терминал" disabled value={containerInfo.terminal_display} />
                        </div>
                    </>
                )}
            </div>
            <div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Button disabled={!containerInfo} variant="outlined" color='primary' size="medium" onClick={onBack}>
                    Назад
                </Button>
                <Button disabled={!containerInfo} variant="contained" color='primary' size="medium" onClick={handleNext}>
                    Продолжить
                </Button>
            </div>
        </div>
    )
}