import { useEffect, useMemo } from 'react';
import { useGetTariffByIdQuery } from '../../../../store/newApi/service/service';

export const useInitFormState = ({ ids, isReady, ...methods }) => {
	const { setValue } = methods;
	const isSingleUpdate = useMemo(() => ids.length === 1, [ids]);
	const { data } = useGetTariffByIdQuery(
		{ id: ids[0] },
		{
			skip: !isSingleUpdate,
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data && isReady && isSingleUpdate) {
			setValue('terminal', data.results[0].terminal);
			setValue('price', data.results[0].price_display && (data.results[0].price_display).toString());
			setValue('unit', data.results[0].unit);
			setValue('name', data.results[0].name);
			setValue('description', data.results[0].description);
		}
	}, [data, setValue, isReady, isSingleUpdate]);
};
