import { buildQueryString } from '../../../newComponents/Filter/utils.js';
import api from '../../api.js';

export const users = api.injectEndpoints({
	endpoints: (builder) => ({
		getEmplsList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/erp/users/?type=1&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetEmplsListQuery, useLazyGetEmplsListQuery } = users;
