import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import styles from './ChooseServicesStep.module.css'
import { useCreateRepairTaskMutation, useDeleteRepairTaskMutation, useLazyGetRepairGroupByIdQuery, useUpdateRepairGroupMutation, useUpdateRepairTaskMutation } from '../../../../../../../store/newApi/service/service';
import { Button, Typography } from '@mui/material';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';
import { RepairTasksInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/RepairTasksInputAutocomplete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RViewerJS from 'viewerjs-react';
import S3Manager from '../../../../../../../helpers/s3storage';
import { isClient } from '../../../../../../../shared/auth/isClient';

const isStepEditable = (stepName) => {
    return ['ТЕУ/ДФЭ', 'Штуки'].includes(stepName);
}

export const ChooseServicesStep = memo(({ containerInfo, repairGroupId, onBack }) => {
    const imageUploader = new S3Manager('damages');
    const [repairGroupInfo, setRepairGroupInfo] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [images, setImages] = useState([]);
    const [price, setPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const disableEdit = useMemo(() => isClient(), [])

    const [createRepairTask, { data: changedRepairGroupData, isSuccess: isCreateSuccess, reset: resetCreate }] = useCreateRepairTaskMutation();
    const [deleteRepairTask, { data: deleteRepairGroupData, isSuccess: isDeleteSuccess, reset: resetDelete }] = useDeleteRepairTaskMutation();
    const [fetchRepairGroup, { data: repairGroupData }] = useLazyGetRepairGroupByIdQuery();
    const [updateRepairGroup, { data: updatedRepairGroup }] = useUpdateRepairGroupMutation();
    const [updateRepairTask, { data: updatedRepairTask }] = useUpdateRepairTaskMutation();

    const repairTasks = useMemo(() => repairGroupInfo?.repair_tasks || [], [repairGroupInfo]);
    const totalPrice = useMemo(() => repairGroupInfo?.total_price || null, [repairGroupInfo]);
    const discountValue = useMemo(() => repairGroupInfo?.discount_value || 0, [repairGroupInfo]);
    const damageImages = useMemo(() => repairGroupInfo?.damage?.pictures[0] || [], [repairGroupInfo]);

    useEffect(() => {
        if (isCreateSuccess) {
            setShowAddForm(false)
            resetCreate()
        }
    }, [isCreateSuccess, resetCreate]);

    useEffect(() => {
        fetchRepairGroup({ id: repairGroupId });
    }, [fetchRepairGroup, repairGroupId])

    useEffect(() => {
        if (isDeleteSuccess)
            fetchRepairGroup({ id: repairGroupId });
    }, [isDeleteSuccess, repairGroupId, fetchRepairGroup])

    useEffect(() => {
        if (repairGroupData)
            setRepairGroupInfo(repairGroupData)
    }, [repairGroupData])

    useEffect(() => {
        if (changedRepairGroupData) {
            setRepairGroupInfo(changedRepairGroupData)
        }
    }, [changedRepairGroupData]);

    useEffect(() => {
        if (updatedRepairGroup) {
            setRepairGroupInfo(updatedRepairGroup)
        }
    }, [updatedRepairGroup]);

    const handleChangePrice = useCallback((e) => {
        setPrice(e.target.value);
    }, []);

    const handleChangePercent = useCallback((e) => {
        setDiscount(e.target.value);
    }, []);

    const handleSave = useCallback(() => {
        updateRepairGroup({id: repairGroupInfo.id, status: 1 })
        onBack()
    }, [updateRepairGroup, repairGroupInfo, onBack]);

    const handleChangeStep = useCallback((taskId, steps) => {
        updateRepairTask({ id: taskId, steps })
    }, [updateRepairTask]);

    const handleDeleteTask = useCallback((id) => {
        deleteRepairTask({ repair_task_pk: id })
    }, [deleteRepairTask])

    const handleAddRepairTask = useCallback((repairTaskId) => {
        if (repairGroupInfo)
            createRepairTask({
                repair_group_pk: repairGroupInfo.id,
                repair_task: repairTaskId
            })
    }, [repairGroupInfo, createRepairTask]);

    const handleUpdatePrice = useCallback((e) => {
        updateRepairGroup({ id: repairGroupInfo.id, total_price: +e.target.value })
    }, [updateRepairGroup, repairGroupInfo])

    const handleUpdatePercent = useCallback((e) => {
        updateRepairGroup({ id: repairGroupInfo.id, discount_value: +e.target.value })
    }, [updateRepairGroup, repairGroupInfo])

    useEffect(() => {
        setPrice(totalPrice);
        setDiscount(discountValue);
    }, [totalPrice, discountValue]);
    
    useEffect(() => {
        if (!images?.length) {
            const promises = damageImages?.map(async (img) => imageUploader.getImage(img.filename));
            Promise
                .all(promises)
                .then(res => setImages(res));
        }
    }, [damageImages, images]);

    if (!repairGroupInfo) return null;

    return (
        <div className={styles.container} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between', gap: '10px' }}>
            <div className={styles.repairServiceContainer}>
                <div className={styles.repairInfo}>
                    <Typography variant="h6">
                        Описание повреждения
                    </Typography>
                    <div style={{ display: 'flex', gap: '12px', flexDirection: 'column', marginTop: '12px' }}>
                        <InputText labelText='Сторона' value={repairGroupInfo.damage?.side} disabled />
                        <InputText labelText='Тип' value={repairGroupInfo.damage?.damage[0]?.name} disabled />
                        <InputText labelText='Область' value={repairGroupInfo.damage?.component[0]} disabled />
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <InputText labelText='Длинна' value={repairGroupInfo.damage?.dimensions?.l} disabled />
                            <InputText labelText='Ширина' value={repairGroupInfo.damage?.dimensions?.w} disabled />
                            <InputText labelText='Глубина' value={repairGroupInfo.damage?.dimensions?.d} disabled />
                        </div>
                    </div>
                    <RViewerJS className={styles.viewer}>
                        {images?.map((image, index) => (
                            <div key={`image_wrapper_${index}`} className={styles.onePhotoCard}>
                                <img src={image} alt="example" />
                            </div>
                        ))}
                    </RViewerJS>
                </div>
                <div className={styles.repairInfo} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">
                        Выбор услуги
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '12px' }}>
                            {repairTasks.map(rt => {
                                return (
                                    <div className={styles.serviceItem} key={rt.id}>
                                        <Button disabled={disableEdit} variant="outlined" color='primary' size="medium" onClick={() => handleDeleteTask(rt.id)}>
                                            <DeleteIcon />
                                        </Button>
                                        <InputText
                                            value={rt.repair_task_display}
                                            labelText="Услуга"
                                            disabled
                                        />
                                        <InputText
                                            defaultValue={rt.steps}
                                            labelText="Шаги"
                                            onBlur={(e) => handleChangeStep(rt.id, e.target.value)}
                                            disabled={!isStepEditable(rt.repair_task_unit) || disableEdit}
                                        />
                                    </div>
                                )
                            })}
                            {showAddForm && <div className={styles.serviceItem}>
                                <Button variant="outlined" color='primary' size="medium" onClick={() => setShowAddForm(false)}>
                                    <DeleteIcon />
                                </Button>
                                <RepairTasksInputAutocomplete
                                    searchParams={{ terminal_id: containerInfo?.terminal }}
                                    labelText="Услуга"
                                    onChange={handleAddRepairTask}
                                />
                            </div>}
                            {!showAddForm && !disableEdit && <Button variant="contained" color='primary' size="medium" sx={{ mt: '12px', width: '42px' }} onClick={() => setShowAddForm(true)}>
                                <AddIcon />
                            </Button>}
                        </div>
                        {totalPrice && <div style={{ marginTop: '20px', display: 'flex', gap: '8px', alignItems: 'flex-end' }}>
                            <div style={{ width: '200px' }}>
                                <InputText disabled={disableEdit} onChange={handleChangePrice} value={price} defaultValue={totalPrice} labelText='Сумма' onBlur={handleUpdatePrice} />
                            </div>
                            <div style={{ width: '100px' }}>
                                <InputText disabled={disableEdit} onChange={handleChangePercent} value={discount} defaultValue={discountValue} labelText='Скидка' onBlur={handleUpdatePercent} icon="%" />
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button variant="outlined" color='primary' size="medium" onClick={onBack}>
                    Назад
                </Button>
                <Button variant="contained" color='primary' size="medium" onClick={handleSave}>
                    Сохранить
                </Button>
            </div>
        </div>
    )
})