import { useEffect, useMemo } from 'react';
import { useGetRepairTasksByIdQuery } from '../../../../store/newApi/service/service';

export const useInitFormState = ({ ids, isReady, ...methods }) => {
	const { setValue } = methods;
	const isSingleUpdate = useMemo(() => ids.length === 1, [ids]);
	const { data } = useGetRepairTasksByIdQuery(
		{ id: ids[0] },
		{
			skip: !isSingleUpdate,
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data && isReady && isSingleUpdate) {
			setValue('terminal', data.results[0].terminal);
			setValue('name', data.results[0].name);
			setValue('repair_component', data.results[0].repair_component);
			setValue('labor_rate_per_hour', data.results[0].labor_rate_per_hour && data.results[0].labor_rate_per_hour.toString());
			setValue('unit', data.results[0].unit);
			setValue('size_step', data.results[0].size_step && data.results[0].size_step.toString());
			setValue('hour_first_step', data.results[0].hour_first_step && data.results[0].hour_first_step.toString());
			setValue('material_first_step', data.results[0].material_first_step && data.results[0].material_first_step.toString());
			if (data.results[0].size_next_step) {
				setValue('size_next_step', data.results[0].size_next_step.toString());
			}
			setValue('hour_next_step', data.results[0].hour_next_step && data.results[0].hour_next_step.toString());
			setValue('material_next_step', data.results[0].material_next_step && data.results[0].material_next_step.toString());
		}
	}, [data, setValue, isReady, isSingleUpdate]);
};
