import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InputDropdown from '../../../Inputs/InputDropdown/InputDropdown';

const InputDropdownGroup = ({ name, title, state, onChange, elements }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleChange = (value) => {
		if (onChange) {
			onChange(name, value);
		}
	};

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', '&:hover': { cursor: 'pointer' } }} onClick={toggleOpen}>
				<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
					{title}
				</Typography>
				<IconButton>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
			</Box>
			{isOpen && <InputDropdown placeholder="Выберите" dropdownItems={elements} onSelect={handleChange} state={state} />}
		</>
	);
};

export default InputDropdownGroup;
