import { Button, Typography } from '@mui/material';
import styles from './ChooseRepairStep.module.css';
import { useCallback, useEffect, useMemo } from 'react';
import { useLazyGetServiceOrdersByIdQuery, useUpdateRepairGroupMutation } from '../../../../../../../store/newApi/service/service';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { isClient } from '../../../../../../../shared/auth/isClient';

const getStatusColor = (statusId) => {
    switch(statusId) {
        case 0:
        case 3:
            return '#BA1A1A';
        case 1:
            return '#5C53A7';
        case 2:
            return '#149527';
        default:
            return 'black';
    }
}

export const ChooseRepairStep = ({ refetchRepairOrder, onChooseRepair, repairOrderId, onNext, onBack, onCancel }) => {
    const [fetchServiceOrder, { data }] = useLazyGetServiceOrdersByIdQuery();
    const [updateRepairGroup, { data: updatedRepairGroup, isSuccess, reset: resetUpdate }] = useUpdateRepairGroupMutation();

    const repairGroups = useMemo(() => data?.repair_group || [], [data]);

    const showAcceptButtons = useCallback((status) => {
        return isClient() && status === 1;
    }, []);

    const handleAccept = useCallback((id) => {
        updateRepairGroup({id, status: 2 })
    }, [updateRepairGroup]);

    const handleDecline = useCallback((id) => {
        updateRepairGroup({id, status: 3 })
    }, [updateRepairGroup]);

    useEffect(() => {
        if (isSuccess) {
            refetchRepairOrder();
            resetUpdate();
        }
    }, [isSuccess, refetchRepairOrder, resetUpdate]);

    useEffect(() => {
        if (repairOrderId) {
            fetchServiceOrder({ id: repairOrderId })
        }
    }, [fetchServiceOrder, repairOrderId]);

    return (
        <div className={styles.container} style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'space-between', flexGrow: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                {repairGroups.map(rg => {
                    return (
                        <div style={{ display: 'flex', flexGrow: 1, gap: '8px' }}>
                            <div className={styles.repairItem} onClick={() => onChooseRepair(rg?.id)}>
                                <div className={styles.repairItemLeft}>
                                    <Typography variant="alertTitle">
                                        {rg?.damage?.component[0]}
                                    </Typography>
                                    <div style={{ marginTop: '12px', fontSize: '12px' }}>
                                        {rg.damage?.side}
                                    </div>
                                    <div style={{ fontSize: '12px' }}>
                                        {rg?.damage?.damage[0]?.name}
                                    </div>
                                </div>
                                <div className={styles.repairItemRight}>
                                    <Typography variant="alertTitle" color={getStatusColor(rg.status)}>
                                        {rg.status_display}
                                    </Typography>
                                    <div style={{ marginTop: '12px', fontSize: '12px' }}>
                                        Кол-во шагов: {rg.step_count}
                                    </div>
                                    <div style={{ fontSize: '12px' }}>
                                        Цена: {rg.total_price}
                                    </div>
                                </div>
                            </div>
                            {showAcceptButtons(rg.status) && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Button variant="contained" color='primary' size="medium" onClick={() => handleAccept(rg.id)}>
                                    <CheckIcon />
                                </Button>
                                <Button variant="contained" color='primary' size="medium" onClick={() => handleDecline(rg.id)}>
                                    <CloseIcon />
                                </Button>
                            </div>}
                        </div>
                    )
                })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button variant="outlined" color='primary' size="medium" onClick={onCancel}>
                    Отмена
                </Button>
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button variant="contained" color='primary' size="medium" onClick={onBack}>
                        Назад
                    </Button>
                    <Button variant="contained" color='primary' size="medium" onClick={onNext}>
                        Продолжить
                    </Button>
                </div>
            </div>
        </div>
    )
}