import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import InputText from '../../Inputs/InputText/InputText';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '../FormWrapper';
import { TerminalInputAutocompleteController } from '../Fields/TerminalInputAutocompleteController';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { useInitFormState } from './hooks/useInitFormState';
import { RepairComponentsInputAutocompleteController } from '../Fields/RepairComponentsInputAutocompleteController';
import { UnitInputAutocompleteController } from '../Fields/UnitInputAutocompleteController';

const createSchema = z.object({
	terminal: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	name: z.string().optional(),
	repair_component: z.number().optional(),
	unit: z.number().optional(),
	labor_rate_per_hour: z.string().optional(),
	size_step: z.string().optional(),
	hour_first_step: z.string().optional(),
	material_first_step: z.string().optional(),
	size_next_step: z.string().optional(),
	hour_next_step: z.string().optional(),
	material_next_step: z.string().optional(),
});

const updateSchema = z.object({
	terminal: z.number().optional(),
	name: z.string().optional(),
	repair_component: z.number().optional(),
	unit: z.number().optional(),
	labor_rate_per_hour: z.string().optional(),
	size_step: z.string().optional(),
	hour_first_step: z.string().optional(),
	material_first_step: z.string().optional(),
	size_next_step: z.string().optional(),
	hour_next_step: z.string().optional(),
	material_next_step: z.string().optional(),
});

export const RepairTasksForm = ({ isOpen, handleClose, entityIds = [] }) => {
	const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
	const isBulkUpdate = useMemo(() => entityIds.length > 1, [entityIds]);
	const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);

	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);

	const methods = useForm({
		defaultValues: {
			terminal: undefined,
			name: '',
			repair_component: undefined, 
			unit: undefined,
			labor_rate_per_hour: '',
			size_step: '',
			hour_first_step: '',
			material_first_step: '',
			size_next_step: '',
			hour_next_step: '',
			material_next_step: '',
		},
		resolver: resolver,
	});

	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, handleClose });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, handleClose });
	useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = methods; 

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	);

	return (
		<DrawerModal
			isOpen={isOpen}
			handleClose={handleClose}
			actions={
				<Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
					Сохранить
				</Button>
			}
		>
			<Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				<TerminalInputAutocompleteController />
				<CollapseBlock title="Название">
					<Controller name="name" control={control} render={({ field }) => <InputText {...field} placeholder="Название" />} />
				</CollapseBlock>
				<RepairComponentsInputAutocompleteController />
				<CollapseBlock title="Стоимость работы ч, $">
					<Controller
						name="labor_rate_per_hour"
						control={control}
						render={({ field }) => <InputText {...field} type="number" placeholder="Стоимость работы ч, $" />}
					/>
				</CollapseBlock>
				<UnitInputAutocompleteController />
				<CollapseBlock title="Размер шага">
					<Controller
						name="size_step"
						control={control}
						render={({ field }) => <InputText {...field} type="number" placeholder="Размер шага" />}
					/>
				</CollapseBlock>
				<CollapseBlock title="Нормочасы за первый шаг, мин">
					<Controller
						name="hour_first_step"
						control={control}
						render={({ field }) => <InputText {...field} type="number" placeholder="Нормочасы за первый шаг, мин" />}
					/>
				</CollapseBlock>
				<CollapseBlock title="Материалы за первый шаг, $">
					<Controller
						name="material_first_step"
						control={control}
						render={({ field }) => <InputText {...field} type="number" placeholder="Материалы за первый шаг, $" />}
					/>
				</CollapseBlock>
				<CollapseBlock title="Размер для след. шага">
					<Controller
						name="size_next_step"
						control={control}
						render={({ field }) => <InputText {...field} type="number" placeholder="Размер для след. шага" />}
					/>
				</CollapseBlock>
				<CollapseBlock title="Нормочасы за след. шаг, мин">
					<Controller
						name="hour_next_step"
						control={control}
						render={({ field }) => <InputText {...field} type="number" placeholder="Нормочасы за след. шаг, мин" />}
					/>
				</CollapseBlock>
				<CollapseBlock title="Материалы за след. шаг, $">
					<Controller
						name="material_next_step"
						control={control}
						render={({ field }) => <InputText {...field} type="number" placeholder="Материалы за след. шаг, $" />}
					/>
				</CollapseBlock>
			</FormWrapper>
		</DrawerModal>
	);
};
