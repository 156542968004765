import { buildQueryString } from '../../../newComponents/Filter/utils.js';
import api from '../../api.js';

export const organizations = api.injectEndpoints({
	endpoints: (builder) => ({
		getClientsList: builder.query({
			query: (params, id) => {
				let queryParams = { ...params };
				if (params?.id) {
					queryParams.id = params.id;
				}
				delete queryParams.uniq;
				return {
					url: `/api/erp/organization/list/?type=2&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getTransportersList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/erp/organization/list/?type=3&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetClientsListQuery, useLazyGetClientsListQuery, useLazyGetTransportersListQuery } = organizations;
