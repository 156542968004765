import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { Button } from '@mui/material';
import { useLazyGetStorageOrdersListQuery } from '../../../../store/newApi/service/service';
import Filter from '../../../../newComponents/Filter/Filter';
import { formatDate } from '../../../../helpers/formatDate';
import { formatPrice } from '../../../../helpers/formatText';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/storage.js';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

function AccountEmplTableStorage() {
	const userType = Cookies.get('userType');
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [fetchStorageOrders, { data, isLoading, isError }] = useLazyGetStorageOrdersListQuery();

	// колонки
	const columnsList = useMemo(
		() => {
			const baseColumns = [ 
			{
				accessorFn: (row) => row.container,
				id: 'service_order__container__container_number',
				header: 'Контейнер',
				size: 200,
			}, 
			{
				accessorFn: (row) => (row.date_from ? formatDate(row.date_from) : '-'),
				id: 'date_from',
				header: 'Дата начала',
				size: 200,
			},
			{
				accessorFn: (row) => (row.date_to ? formatDate(row.date_to) : '-'),
				id: 'date_to',
				header: 'Дата окончания',
				size: 200,
			},
			{
				accessorFn: (row) => {
					const tariff = row.client_tariff;
					if (tariff && tariff.includes('/')) {
						const [price] = tariff.split('/');
						return formatPrice(parseFloat(price));
					}
					return formatPrice(row.client_tariff);
				},
				id: 'client_tariff',
				header: 'Тариф клиента, ₽/день',
				size: 280,
			},
			{
				accessorFn: (row) => (row.total_price ? formatPrice(row.total_price) : '-'),
				id: 'service_order__service_order_prices__total_price',
				header: 'Стоимость',
				size: 200,
			},
			{
				accessorFn: (row) => row.free_days_count,
				id: 'free_days_count',
				header: 'Бесплатные дни',
				size: 200,
				sortDescFirst: false,
			},
		];
	
			
		if (userType == 1) {
			baseColumns.push({
				accessorFn: (row) => {
					const url = `/accountEmpl/clients?client=${row.client_id}`;
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.client}
						</a>
					);
				},
				id: 'service_order__client__organization_name',
				header: 'Клиент',
				size: 250,
				sortDescFirst: false,
			});
		}

		return baseColumns;
	},
	[userType], 
);

	// порядок колонок
	const columnOrdering = [
		'mrt-row-select',
		'service_order__container__container_number',
		'service_order__client__organization_name',
		'client_tariff',
		'service_order__service_order_prices__total_price',
		'free_days_count',
		'date_from',
		'date_to',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Хранение'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium">
						Добавить акт
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: ['client_tariff_gte', 'client_tariff_lte'],
								title: 'Тариф клиента',
								text: 'тариф',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
							{
								name: ['date_from_after', 'date_from_before'],
								title: 'Дата начала',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['date_to_after', 'date_to_before'],
								title: 'Дата окончания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetchStorageOrders}
						isLoading={isLoading}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="storageRtk"
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableStorage;
