import { TextField, InputAdornment, Typography } from '@mui/material';
import { inputText } from './InputText.styles.js';

function InputText({ type = 'text', placeholder, value, onChange, icon, iconPosition = 'end', errorMessage, height = '40px', labelText, ...rest }) {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
			{labelText && <span>{labelText}</span>}
			<TextField
				type={type}
				variant="outlined"
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				fullWidth
				sx={{
					...inputText.textField,
					...(value && inputText.valueTextField),
					height
				}}
				InputProps={{
					startAdornment:
						iconPosition === 'start' ? (
							<InputAdornment position="start">
								<div style={inputText.icon}>{icon}</div>
							</InputAdornment>
						) : null,
					endAdornment:
						iconPosition === 'end' ? (
							<InputAdornment position="end">
								<div style={inputText.icon}>{icon}</div>
							</InputAdornment>
						) : null,
				}}
				{...rest}
			/>
			{errorMessage && (
				<Typography color="error" variant="caption">{errorMessage}</Typography>
			)}
		</div>
	);
}

export default InputText;
