import { Radio, RadioGroup, FormControlLabel, Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { CONTAINER_SIZE, CONTAINER_TYPE } from '../../../shared/containers/constants';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import InputText from '../../Inputs/InputText/InputText';
import { Switcher } from '../../../components';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '../FormWrapper';
import { LineInputAutocomplete } from '../Fields/LineInputAutocomplete';
import { TerminalInputAutocompleteController } from '../Fields/TerminalInputAutocompleteController';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { ClientInputAutocompleteController } from '../Fields/ClientInputAutocompleteController';
import { useInitFormState } from './hooks/useInitFormState';
import { isClient } from '../../../shared/auth/isClient';

const createSchema = z.object({
	containerNumber: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	terminal: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	client: isClient() ? z.number().optional() : z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	size: z
		.string()
		.optional()
		.transform((val) => +val),
	type: z
		.string()
		.optional()
		.transform((val) => +val),
	line: z.number().optional(),
	bookingNumber: z.string().optional(),
	isFilled: z.boolean().optional(),
	isDamaged: z.boolean().optional(),
	isDangerous: z.boolean().optional(),
});

const updateSchema = z.object({
	containerNumber: z.string().optional(),
	terminal: z.number().optional(),
	client: z.number().optional(),
	size: z
		.string()
		.optional()
		.transform((val) => +val),
	type: z
		.string()
		.optional()
		.transform((val) => +val),
	line: z.number().optional(),
	bookingNumber: z.string().optional(),
	isFilled: z.boolean().optional(),
	isDamaged: z.boolean().optional(),
	isDangerous: z.boolean().optional(),
});

export const ContainerForm = ({ isOpen, handleClose, refetchData, entityIds = [] }) => {
	const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
	const isBulkUpdate = useMemo(() => entityIds.length > 1, [entityIds]);
	const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);

	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);

	const methods = useForm({
		defaultValues: {
			line: undefined,
			containerNumber: '',
			size: undefined,
			type: undefined,
			client: undefined,
			bookingNumber: '',
			isFilled: false,
			isDangerous: false,
			isDamaged: false,
			terminal: undefined,
		},
		resolver: resolver,
	});

    const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, handleClose, refetchData });
    const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, handleClose, refetchData });
    useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

    const { handleSubmit, control, watch, setValue, formState: { errors } } = methods;
    const isFilled = watch('isFilled');
    const isDangerous = watch('isDangerous');
    const isDamaged = watch('isDamaged');


	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	);

	return (
		<DrawerModal
			isOpen={isOpen}
			handleClose={handleClose}
			actions={
				<Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
					Сохранить
				</Button>
			}
		>
			<Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				<TerminalInputAutocompleteController />
				{!isClient() && <ClientInputAutocompleteController />}
				{!isBulkUpdate && (
					<CollapseBlock defaultOpen={!!errors.containerNumber} title={<span>№ Контейнера</span>}>
						<Controller
							name="containerNumber"
							control={control}
							render={({ field }) => (
								<>
									<InputText {...field} placeholder="Введите номер" />
									{errors?.containerNumber && (
										<Typography color="error" variant="caption">
											{errors?.containerNumber?.message}
										</Typography>
									)}
								</>
							)}
						/>
					</CollapseBlock>
				)}
				<LineInputAutocomplete />
				<CollapseBlock title="Размер">
					<Controller
						name="size"
						control={control}
						render={({ field }) => (
							<RadioGroup {...field}>
								{Object.values(CONTAINER_SIZE).map((size) => (
									<FormControlLabel key={size.id} value={size.id} control={<Radio />} label={size.value} />
								))}
							</RadioGroup>
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Тип">
					<Controller
						name="type"
						control={control}
						render={({ field }) => (
							<RadioGroup {...field}>
								{Object.values(CONTAINER_TYPE).map((type) => (
									<FormControlLabel key={type.id} value={type.id} control={<Radio />} label={type.value} />
								))}
							</RadioGroup>
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Букинг">
					<Controller
						name="bookingNumber"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Введите номер" />}
					/>
				</CollapseBlock>
				<CollapseBlock title="Заполнен">
					<Switcher
						onChecked={(checked) => setValue('isFilled', checked, { shouldTouch: true })} // Setting value in form state
						externalChecked={isFilled}
						right="Да"
					/>
				</CollapseBlock>
				<CollapseBlock title="Опасный груз">
					<Switcher
						onChecked={(checked) => setValue('isDangerous', checked, { shouldTouch: true })} // Setting value in form state
						externalChecked={isDangerous}
						right="Да"
					/>
				</CollapseBlock>
				<CollapseBlock title="Поврежден">
					<Switcher
						onChecked={(checked) => setValue('isDamaged', checked, { shouldTouch: true })} // Setting value in form state
						externalChecked={isDamaged}
						right="Да"
					/>
				</CollapseBlock>
			</FormWrapper>
		</DrawerModal>
	);
};
