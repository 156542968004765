import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';
import { useCallback } from 'react';
import { useLazyGetClientsListQuery } from '../../../store/newApi/organizations/organizations';

export const ClientInputAutocomplete = ({ value, onChange, ...rest }) => {
	const [trigger, { clientsOptions = [], loading: loadingClients }] = useLazyGetClientsListQuery({
		selectFromResult: ({ data }) => ({
			clientsOptions: data?.results?.map((t) => ({ label: t.organization_name, id: t.id })),
		}),
	});
	const handleSearchClients = useCallback(
		(value) => {
			trigger({ search: value });
		},
		[trigger],
	);
	return (
		<InputAutocompleteAsync
			{...rest}
			value={value}
			fetchFn={handleSearchClients}
			placeholder="Выберите или введите вручную"
			options={clientsOptions}
			loading={loadingClients}
			onChange={onChange}
		/>
	);
};
