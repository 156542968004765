import { buildQueryString } from '../../../newComponents/Filter/utils.js';
import { getValidatedFields } from '../../../shared/api/getValidatedFields.js';
import api from '../../api.js';

export const containers = api.injectEndpoints({
	endpoints: (builder) => ({
		getContainersList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								if (id === 'line') {
									field = 'line__line_name';
								} else if (id === 'org') {
									field = 'order_container__client__organization_name';
								} else {
									field = id;
								}
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/containers/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getContainerById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/containers/${id}/detail/`,
					method: 'GET',
				}
			}
		}),
		getLinesList: builder.query({
			query: () => {
				return {
					url: `/api/containers/lines`,
					method: 'GET',
				};
			},
		}),
		updateContainers: builder.mutation({
			query: ({ container_ids, ...payload }) => {
				console.log('data, ', payload);
				const validatedContainerPayload = Object.entries(payload)
					.filter(([key, value]) => value !== "" && value !== undefined)
					.reduce((result, [key, value]) => ({ ...result, [key]: value }), {})
				return {
					url: `/api/containers/bulk-update/`,
					method: 'PUT',
					body: {
						container_ids,
						...validatedContainerPayload
					},
				}
			}
		}),
		createContainer: builder.mutation({
			query: ({ terminalId, orgId, ...payload }) => {
				console.log('data, ', terminalId, payload);
				const validatedContainerPayload = Object.entries(payload)
					.filter(([key, value]) => value !== "" && value !== undefined)
					.reduce((result, [key, value]) => ({ ...result, [key]: value }), {})
				return {
					url: `/api/containers/${terminalId}/create/`,
					method: 'POST',
					body: {
						org_id: orgId,
						container: {
							...validatedContainerPayload,
						}
					},
				}
			}
		})
	}),
});

export const {
	useGetContainersListQuery,
	useLazyGetContainersListQuery,
	useGetLinesListQuery,
	useCreateContainerMutation,
	useUpdateContainersMutation,
	useGetContainerByIdQuery,
	useLazyGetContainerByIdQuery,
} = containers;
