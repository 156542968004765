import React, { useEffect } from 'react';
import styles from '../AccountEmpl.module.scss';
import { useParams } from 'react-router-dom';
import Header from '../../../../newComponents/Header/Header';
import { useLazyGetEntryQuery, useUpdateEntryMutation } from '../../../../store/newApi/entries/entries';
import { Box, Button } from '@mui/material';
import { Preloader } from '../../../../components';
import { account } from './Account.styles.js';
import { EntriesForm } from '../../../../newComponents/Form/EntriesForm/EntriesForm.jsx';
import { useNavigate } from 'react-router-dom';

function AccountEmplEntryPage() {
	let { terminalId, orderId, entryId } = useParams();
	const [trigger, { data, isLoading }] = useLazyGetEntryQuery({});
	const [updateEntry, {isSuccess: isCreateSuccess}] = useUpdateEntryMutation();
	const navigate = useNavigate();

	useEffect(() => {
		trigger({ terminalId, orderId, entryId });
	}, [terminalId, orderId, entryId]);

	const handleChangeStatus = () => {
		updateEntry({ terminalId, orderId, pass_pk: entryId, status: 2 });
	
	};

	useEffect(() => {
		if (isCreateSuccess) {
			const timer = setTimeout(() => {
				navigate('/accountEmpl/entries')
			}, 1000);
		
			return () => {
			  clearTimeout(timer);
			};
		}
	
	  }, [isCreateSuccess]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={isLoading ? '' : `${data ? data?.name : ''}`}
				btnDesktop={
					data?.status == 0 || data?.status == 1
						? [
								<Button variant="contained" color="primary" onClick={handleChangeStatus}>
									Активировать пропуск
								</Button>,
							]
						: [data?.status_display]
				}
				btnMobile={
					data?.status == 0 || data?.status == 1
						? [
								<Button variant="contained" color="primary" onClick={handleChangeStatus}>
									Активировать
								</Button>,
							]
						: [data?.status_display]
				}
			/>
			{isLoading ? (
				<Box sx={account.preloader}>
					<Preloader />
				</Box>
			) : (
				<div className={styles.account__container}>
					<div className={styles.account__stack}>
						<EntriesForm />
					</div>
				</div>
			)}
		</div>
	);
}

export default AccountEmplEntryPage;
